import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";

class Welcome extends Component {
  render() {
    let { profile, navActions, appActions, goToSetUrl, from } = this.props;
    return (
      <Wrapper>
        <img className="bg-left-top" src="/images/left-top-bg.png" />
        <img className="bg-right-bottom" src="/images/right-bottom-bg.png" />
        <img className="logo" src="/images/nfc-logo.png" />

        <div className="container">
          <div className="title">
            <h1>Hello!</h1>
            <p>welcome to NFCTap.me</p>
          </div>

          {from === "RedirectPage" ? (
            <div className="button-section">
              <div className="button-long-strip" onClick={goToSetUrl}>
                <img src="/images/icon01.png" />
                <span>SET RDT</span>
              </div>
              {!profile && (
                <div
                  className="button-long-strip"
                  onClick={() => navActions.push("/social-login")}
                >
                  <img src="/images/icon02.png" />
                  <span>LOGIN</span>
                </div>
              )}
            </div>
          ) : (
            //from === "LandingPage"
            <div className="button-section">
              {profile ? (
                <div
                  className="button-long-strip"
                  onClick={() => appActions.logout()}
                >
                  <img src="/images/icon02.png" />
                  <span>LOGOUT</span>
                </div>
              ) : (
                <div
                  className="button-long-strip"
                  onClick={() => navActions.push("/social-login")}
                >
                  <img src="/images/icon02.png" />
                  <span>LOGIN</span>
                </div>
              )}
            </div>
          )}
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;

  & > .bg-left-top {
    position: absolute;
    max-width: 411px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;

    @media screen and (max-width: 414px) {
      max-width: 350px;
    }

    @media screen and (max-width: 375px) {
      max-width: 270px;
    }
  }

  & > .bg-right-bottom {
    position: absolute;
    max-width: 411px;
    width: 100%;
    bottom: 0;
    right: 0;
    z-index: 1;

    @media screen and (max-width: 414px) {
      max-width: 350px;
    }

    @media screen and (max-width: 375px) {
      max-width: 270px;
    }
  }

  & > .logo {
    position: absolute;
    padding: 5px;
    width: 60px;
    left: 0;
    bottom: 0;
    z-index: 1;
  }

  & > .container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;
    z-index: 2;

    & > .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      & > h1 {
        margin-bottom: 20px;
        border-bottom: 5px solid #e6e54f;
        max-width: 200px;
        letter-spacing: 4px;
        font-size: 60px;
        color: #224e86;
      }

      & > p {
        margin-bottom: 80px;
        padding: 0px 10px;
        text-align: center;
        letter-spacing: 4px;
        font-size: 26px;
        color: #224e86;

        @media screen and (max-width: 375px) {
          margin-bottom: 30px;
        }
      }
    }

    & > .button-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      & > .button-long-strip {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        padding: 10px 10px 10px 60px;
        box-shadow: 0 2px 15px 0 rgba(105, 105, 105, 0.5);
        border-radius: 30px;
        max-width: 300px;
        width: 100%;
        cursor: pointer;

        & > img {
          margin-right: 20px;
        }

        & > span {
          font-size: 20px;
          color: #224e86;
        }

        @media screen and (max-width: 360px) {
          margin-bottom: 15px;
        }

        @media screen and (max-width: 320px) {
          padding: 10px;
          max-width: 180px;
        }
      }
    }

    @media screen and (max-width: 414px) {
      padding-bottom: 50px;
    }

    @media screen and (max-width: 320px) {
      padding-bottom: 0px;
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(Welcome);
