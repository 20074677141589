import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withPage } from "../Page";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";
import Welcome from "../Components/Welcome";

const UiState = {
  welcome: "welcome",
  setUrl: "setUrl"
};

class RedirectPage extends Component {
  constructor(props) {
    super(props);
    let { profile } = this.props;
    this.state = {
      uiState: UiState.welcome,
      data: {
        name: "",
        url: ""
      },
      bindAccount: true,
      showSpinner: true,
      result: null,
      errorMsg: ""
    };
  }

  componentDidMount() {
    let { appActions, navActions, uid } = this.props;

    new Promise(resolve => setTimeout(resolve, 600))
      .then(() => appActions.checkTag(uid))
      .then(result => {
        this.setState({ result });
        appActions.setLocalSettingUid(uid);
        if (result && result.url) {
          window.location = result.url;
        }

        if (result && result.url === "") {
          this.setState({ showSpinner: false });
        }
      })
      .catch(err => {
        navActions.push("/error");
        console.warn(err);
      });
  }

  render() {
    let { profile, uid, siteConfig } = this.props;
    let { uiState, data, bindAccount, showSpinner, errorMsg } = this.state;

    if (showSpinner) {
      return (
        <Spinner>
          <img src="/images/loading.png" />
          <div>Loading...</div>
        </Spinner>
      );
    }

    if (uiState === UiState.welcome) {
      return (
        <Welcome
          from="RedirectPage"
          goToSetUrl={() => this.setState({ uiState: UiState.setUrl })}
        />
      );
    } else if (uiState === UiState.setUrl) {
      return (
        <Wrapper>
          <div className="container">
            <div
              className="backbutton"
              onClick={() => this.setState({ uiState: UiState.welcome })}
            >
              <Icon.ArrowBack color="white" />
            </div>

            <h1>{`EDIT\nRDT`}</h1>

            <div className="form">
              <div>
                <h3>UID：{uid}</h3>
                {profile && (
                  <div style={{ display: "flex" }}>
                    <h3>是否綁定：</h3>
                    <div
                      onClick={() => this.setState({ bindAccount: true })}
                      style={{ display: "flex", alignItems: "center", flex: 1 }}
                    >
                      {bindAccount ? (
                        <Icon.RadioButtonChecked
                          color="#224e86"
                          style={{ marginRight: 5 }}
                        />
                      ) : (
                        <Icon.RadioButtonUnchecked
                          color="#224e86"
                          style={{ marginRight: 5 }}
                        />
                      )}
                      <span>是</span>
                    </div>

                    <div
                      onClick={() => this.setState({ bindAccount: false })}
                      style={{ display: "flex", alignItems: "center", flex: 1 }}
                    >
                      {!bindAccount ? (
                        <Icon.RadioButtonChecked
                          color="#224e86"
                          style={{ marginRight: 5 }}
                        />
                      ) : (
                        <Icon.RadioButtonUnchecked
                          color="#224e86"
                          style={{ marginRight: 5 }}
                        />
                      )}
                      <span>否</span>
                    </div>
                  </div>
                )}
              </div>
              <div>
                <Widget.Input
                  placeholder="TAG名稱"
                  value={data.name || ""}
                  onChange={this._onInputUpdate("name")}
                  extraCss="max-width: 180px; width: 100%;"
                />
              </div>
              <div style={{ padding: 15 }}>
                <Widget.IconInput
                  icon={"/images/link.png"}
                  placeholder="請輸入URL"
                  value={data.url || ""}
                  onChange={this._onInputUpdate("url")}
                />
              </div>
              {errorMsg && (
                <div
                  style={{
                    maxWidth: 300,
                    padding: "0px 15px",
                    fontSize: 14,
                    color: "red",
                    textAlign: "center"
                  }}
                >
                  {errorMsg}
                </div>
              )}
              <Widget.SubmitButton
                text="儲存"
                onClick={() => this._onSubmit()}
              />
            </div>
          </div>
        </Wrapper>
      );
    }
  }

  _onInputUpdate = key => e => {
    let { data } = this.state;
    this.setState({
      data: { ...data, [key]: e.target.value }
    });
  };

  _onSubmit = () => {
    let { navActions, appActions, uid, profile } = this.props;
    let { data, bindAccount } = this.state;

    if (data.name === "") {
      return this.setState({ errorMsg: "請輸入tap名稱！" });
    }

    if (data.url === "") {
      return this.setState({ errorMsg: "請輸入URL!" });
    }

    appActions
      .editTag({
        ...data,
        uid,
        bindAccount
      })
      .then(result => {
        this.setState({ data: {} });
        appActions.clearLocalSettingUid();
        navActions.push("/success");
      })
      .catch(err => console.warn(err));
  };
}

const Spinner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;
  padding: 10px;

  & > img {
    max-width: 200px;
    width: 100%;
    max-height: 200px;
    height: 100%;
  }

  & > div {
    font-size: 20px;
    animation-name: loading;
    animation-duration: 800ms;
    animation-iteration-count: infinite;

    @keyframes loading {
      from {
        color: #3f2628;
      }
      to {
        color: #7e7e7e;
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  background-image: url("/images/setting-bg.png");
  background-repeat: no-repeat;
  background-position: left bottom;

  & .backbutton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #72a2dd;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    z-index: 2;

    position: absolute;
    top: 10px;
    left: 10px;
  }

  & .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 60px;
    width: 100vw;
    height: 100vh;
    z-index: 1;

    & > h1 {
      border-bottom: 5px solid #e6e54f;
      margin-bottom: 90px;
      max-width: 300px;
      width: 100%;
      text-align: right;
      white-space: pre-wrap;
      letter-spacing: 4px;
      font-size: 60px;
      color: #224e86;
    }

    & > .form {
      display: flex;
      flex-direction: column;
      max-width: 300px;
      width: 100%;
      margin-bottom: 30px;

      & > div {
        padding: 15px 15px 0px;

        & h3 {
          font-weight: 600;
          font-size: 20px;
          color: #224e86;
        }
      }

      & > button {
        align-self: flex-end;
      }
    }

    @media screen and (max-width: 414px) {
      & > h1 {
        margin-bottom: 0px;
      }
      padding-bottom: 50px;
    }

    @media screen and (max-width: 320px) {
      padding-bottom: 0px;
    }
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      uid: Selectors.getQueryParams(ownProps).uid,
      siteConfig: Selectors.getSiteConfig(state)
    }),
    ActionCreator
  )(RedirectPage)
);
